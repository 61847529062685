<template>
  <div id="contact-recycle" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div class="flex">
        <!-- <zc-search
          open_path="{$webPath}"
          :address_list="address"
          title_type="user"
          title="contact_reycle"
          :search_data="searchForm"
          callback="getData"
          :key="searchKey"
          :must_search="mustSearch"
        ></zc-search> -->
        <el-input
          v-model="key"
          class="search-input"
          :placeholder="$t('taskManagement.inputKeyword')"
          @keyup.enter.native="getList(0)"
        ></el-input>
        <el-button class="search-button-1" @click="getList(0)">{{
          $t("search")
        }}</el-button>
        <el-button class="search-button-2" @click="reset">{{
          $t("reset")
        }}</el-button>
        <!-- <zc-icon-btn
          content="<tags:lang>search</tags:lang>"
          btn_type="primary"
          btn_class="search_button"
          icon="icon&#45;&#45;search"
          @click="getList(0)"
        ></zc-icon-btn>
        <zc-icon-btn
          content="<tags:lang>reset</tags:lang>"
          btn_type="success"
          btn_class="search_button"
          icon="icon&#45;&#45;zhongzhi"
          @click="reset"
        ></zc-icon-btn> -->
      </div>
      <div class="titH">
        {{ $t("taskManagement.recycle") }}
      </div>
      <div :style="{ width: tableWidth }">
        <el-table
          :data="tableData"
          row-key="id"
          align="left"
          @selection-change="itemChange"
          highlight-current-row
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            class-name="can-move"
            show-overflow-tooltip
            v-for="(item, index) in col"
            :width="widthObj[item.prop]"
            :key="is_show == 0 ? `col_${index}` : `dro_${index}`"
            :prop="dropCol[index].prop"
            :label="item.label"
          >
            <template slot="header">
              <span v-if="item.prop == 'name'">
                &nbsp;&nbsp;&nbsp;&nbsp;{{ item.label }}
              </span>
              <template v-else>
                {{ item.label }}
              </template>
            </template>
            <template slot-scope="scope">
              <div v-if="dropCol[index].prop == 'tags_name'">
                <div
                  v-for="(it, index) in scope.row.tags_name"
                  :style="'background-color:' + it.value"
                  class="select-tags flex-center"
                  :key="index"
                >
                  {{ it.name }}
                </div>
              </div>
              <div v-else-if="dropCol[index].prop == 'star' && scope.row.star">
                <el-rate v-model="scope.row.star" disabled text-color="#ff9900">
                </el-rate>
              </div>
              <div v-else-if="dropCol[index].prop == 'name'">
                <el-tag @click="view(scope.row.id, 1)">{{
                  scope.row.name
                }}</el-tag>
              </div>
              <div v-else>
                <p>{{ scope.row[dropCol[index].prop] }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('handle')"
            width="100"
            fixed="right"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                @click="contactActivation(scope.row.id, 0)"
                size="mini"
                v-if="rightShow('CrmContact@contactActivation')"
                >{{ $t("taskManagement.restore") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex-a-center page-box">
        <div class="select-tit">
          {{ $t("taskManagement.selected") }}{{ selectNum }}/{{ pageSize }}
        </div>
        <el-button
          type="primary"
          @click="contactActivation(0, 1)"
          v-if="rightShow('CrmContact@contactActivation')"
          size="mini"
          >{{ $t("taskManagement.restore") }}</el-button
        >
        <el-pagination
          @size-change="getList(1, searchData)"
          @current-change="getList(1, searchData)"
          :current-page.sync="page"
          :page-sizes="[20, 40, 60, 80, 100]"
          :page-size.sync="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total.sync="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { toMessage } from "@/utils/message";
import Sortable from "sortablejs";
import "@/plugins/move";
export default {
  name: "ContactRecycle",
  data() {
    return {
      cacheAddr: {},
      searchData: {},
      address: {},
      searchForm: [
        "公司名称",
        "公司邮箱",
        "客户简介",
        "客户来源",
        "登录名",
        "有无登录名",
        "公司地址",
        "客户分组",
        "客户区域",
        "客户标签",
        "制单人",
        "负责人",
        "联系人名称",
        "联系人电话",
        "联系人邮件",
        "联系人称谓",
        "联系人地址",
        "创建时间",
      ],
      mustSearch: ["公司名称", "客户区域", "负责人", "联系人名称"],
      is_show: 0,
      visible: false,
      addr: true,
      searchKey: 1,
      checkList: [],
      checkListField: [],
      selectNum: 0,
      selectType: "",
      loading: true,
      page: 1,
      pageSize: 20,
      key: "",
      total: 0,
      sortProp: "",
      sortOrder: "",
      tableData: [],
      is_first: 1,
      allDisColumn: [],
      searchConfig: {
        date: {
          type: "date",
          startName: this.$t("contactList.startDate"),
          link: this.$t("taskManagement.to"),
          endName: this.$t("contactList.endDate"),
          name: this.$t("contactList.selectCreateDate"),
          default: "",
        },
        key: {
          type: "input",
          desc: this.$t("taskManagement.inputKeyword"),
          name: this.$t("search"),
          default: "",
        },
        group: {
          type: "select",
          desc: this.$t("contactList.userGroup"),
          option: [],
          default: "",
        },
      },
      allShowColumn: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      col: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      dropCol: [
        {
          label: this.$t("contactList.companyName"),
          prop: "name",
        },
        {
          label: this.$t("contactList.contact"),
          prop: "consignee",
        },
        {
          label: this.$t("contactList.label"),
          prop: "tags_name",
        },
        {
          label: this.$t("contactList.accessionName"),
          prop: "account",
        },
        {
          label: this.$t("contactList.createDate"),
          prop: "create_time",
        },
        {
          label: this.$t("contactList.userGroup"),
          prop: "group_name",
        },
        {
          label: this.$t("contactList.star"),
          prop: "star",
        },
        {
          label: this.$t("contactList.lastContact"),
          prop: "update_time",
        },
        {
          label: this.$t("contactList.news"),
          prop: "content",
        },
      ],
      widthObj: {
        name: "200",
        account: "150",
        create_time: "200",
        consignee: "200",
        tags_name: "250",
        group_name: "200",
        star: "200",
        update_time: "200",
        content: "200",
      },
    };
  },
  computed: {
    // 计算表格宽度
    tableWidth: function () {
      let wArr = [];
      this.col.forEach((item) => {
        wArr.push(this.widthObj[item.prop]);
      });
      let temW = wArr.reduce(function (prev, cur) {
        return prev * 1 + cur * 1;
      });
      console.log(temW);
      // 多选框以及操作栏的宽度155
      if (temW + 155 > 1400) {
        return "100%";
      } else {
        return temW + 155 + "px";
      }
    },
  },
  inject: ["rightShow"],
  methods: {
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    //选择框改变
    itemChange(val) {
      this.checkList = [];
      val.forEach((item) => {
        this.checkList.push(item.id);
      });
      this.selectNum = this.checkList.length;
    },
    //获取callback的内容
    getData(data) {
      let ajax = {
        ...data.searchData,
      };
      this.searchData = ajax;
      if (data.type === 1) {
        this.getList(2, this.searchData);
      }
    },
    //列拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(".el-table__header-wrapper tr");
      Sortable.create(wrapperTr, {
        animation: 180,
        delay: 0,
        handle: ".can-move",
        onEnd: (evt) => {
          const oldItem = this.dropCol[evt.oldIndex - 1];
          this.dropCol.splice(evt.oldIndex - 1, 1);
          this.dropCol.splice(
            evt.newIndex - 1,
            0,
            JSON.parse(JSON.stringify(oldItem))
          );
          // let obj = {};
          // obj['name'] = "CrmContact_contactRecycle"
          // obj['option'] = this.dropCol
          // savePageOption(obj, "{$webPath}")
        },
        // 多选框禁止拖动
        onMove: (evt) => {
          if (evt.related.className.indexOf("el-table-column--selection") > 0) {
            return false;
          }
        },
      });
    },
    //激活
    contactActivation(id, operation) {
      let ids = [];
      if (id == 0) {
        ids = this.checkList;
      } else {
        ids = [id];
      }
      if (ids.length == 0) {
        this.$message(toMessage("error", this.$t("contactList.error")));
        return;
      }
      this.$confirm(this.$t("contactList.confirmToRestore"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.axios
          .post("/home/CrmContact/contactActivation", {
            ids: ids,
            operation: operation,
          })
          .then((res) => {
            this.loading = false;
            if (res.data.status !== 1) {
              this.$message(toMessage("error", res.data.msg));
              return;
            }
            this.getList(0);
            this.checkList = [];
          })
          .catch(() => {});
      });
    },
    //查看 1
    view(id, is_confirm) {
      this.addTab(
        "CrmContact",
        "contactEdit",
        "<tags:lang>客户详情</tags:lang>",
        {
          id: id,
          is_view: is_confirm,
          is_delete: 1,
        }
      );
    },
    reset() {
      this.selectNum = 0;
      this.key = "";
      this.page = 1;
      this.pageSize = 20;
      this.getList(0);
    },
    getList(operation, ajaxData = {}) {
      let ajax = {};
      this.page = operation === 2 ? 1 : this.page;
      if (operation == 0) {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          key: this.key.trim(),
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          is_first: this.is_first,
        };
      } else {
        ajax = {
          page: this.page,
          pageSize: this.pageSize,
          sortProp: this.sortProp,
          sortOrder: this.sortOrder,
          is_first: this.is_first,
          ...ajaxData,
        };
      }
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getContactRecycleList", ajax)
        .then((res) => {
          this.loading = false;
          if (res.data.status !== 1) {
            this.$message(toMessage("error", res.data.msg));
            return;
          }
          if (this.is_first) {
            this.searchConfig.group.option = res.data.data.group_list;
            if (res.data.data.habit_page) {
              let listCol = {};
              this.allShowColumn.forEach((item) => {
                listCol[item.prop] = item;
              });
              this.col = [];
              this.dropCol = [];
              res.data.data.habit_page.forEach((item) => {
                this.col.push(listCol[item]);
                this.dropCol.push(listCol[item]);
                //this.col = JSON.parse(JSON.stringify(res.data.habit_page))
                //this.dropCol = JSON.parse(JSON.stringify(res.data.habit_page))
              });
            }
            if (this.is_show == 1) {
              this.is_show = 0;
            } else {
              this.is_show = 1;
            }
            this.is_first = 0;
          }
          this.selectNum = 0;
          this.tableData = res.data.data.list;
          this.total = res.data.data.total;
        })
        .catch(() => {});
    },
  },
  created: function () {
    this.getList(0);
  },
  mounted() {
    this.columnDrop();
    this.timer = setInterval(() => {
      try {
        if (self.addr && this.cacheAddr) {
          self.address = this.cacheAddr;
          self.addr = false;
          self.searchKey++;
        }
        if (!self.addr && this.cacheAddr) {
          clearInterval(this.timer);
        }
      } catch (e) {
        console.log("");
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  beforeCreate() {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
    });
  },
};
</script>

<style scoped>
#contact-recycle {
  padding: 28px !important;
  overflow: auto;
  height: 100%;
}
.container {
  max-width: 1700px;
  height: 100%;
  background: #fff;
}
.status-box {
  margin-top: 20px;
  margin-bottom: 20px;
}
.table-box {
  margin-left: 5px;
  min-width: 1280px;
  max-width: 2200px;
}
.search-box {
  padding-left: 15px;
}
.order-icon {
  font-size: 23px;
}
.select-tags {
  height: 30px;
  float: left;
  padding: 4px 14px;
  color: #fff;
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 3px;
  max-width: 180px;
}
.popover-left {
  width: 45%;
  height: 300px;
  float: left;
  position: relative;
}
.popover-right {
  float: right;
  width: 45%;
  height: 300px;
  position: relative;
}
.popover-left-top {
  width: 100%;
  height: 20px;
}
.popover-left-bottom {
  width: 100%;
  height: 260px;
  border: 1px solid #000;
}
.popover-left-button {
  width: 100%;
  height: 20px;
  float: right;
}
.popover-right-button {
  width: 100%;
  height: 20px;
  float: left;
}
.search-input {
  width: 467px;
}
.company-name {
  padding-left: 30px;
}
.titH {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 24px;
}
.select-tit {
  font-size: 14px;
  color: #333333;
  margin-right: 24px;
}
.el-pagination {
  margin: 0 auto;
}
.page-box {
  margin-top: 37px;
}
.el-table th:not(:first-child) {
  cursor: move;
}
</style>